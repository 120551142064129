/**
Core script to handle the entire theme and core functions
**/
var YogaZone = function () {
    /* Search Bar ============ */
    siteUrl = '';

    var screenWidth = $(window).width();

    var screenWidth = $(window).width();

    var homeSearch = function () {
        'use strict';
        /* top search in header on click function */
        var quikSearch = jQuery("#quik-search-btn");
        var quikSearchRemove = jQuery("#quik-search-remove");

        quikSearch.on('click', function () {

            jQuery('.dez-quik-search').fadeIn(500);
            jQuery('.dez-quik-search').addClass('On');

        });

        quikSearchRemove.on('click', function () {
            jQuery('.dez-quik-search').fadeOut(500);
            jQuery('.dez-quik-search').removeClass('On');

        });
        /* top search in header on click function End*/
    }

    var cartButton = function () {
        $(".item-close").on('click', function () {
            $(this).closest(".cart-item").hide('500');
        });
        $('.cart-btn').unbind().on('click', function () {
            $(".cart-list").slideToggle('slow');
        })

    }


    /* One Page Layout ============ */
    var onePageLayout = function () {
        'use strict';
        var headerHeight = parseInt($('.onepage').css('height'), 10);
        $(".scroll").unbind().on('click', function (event) {
            event.preventDefault();

            if (this.hash !== "") {
                var hash = this.hash;
                var seactionPosition = $(hash).offset().top;
                var headerHeight = parseInt($('.onepage').css('height'), 10);


                $('body').scrollspy({
                    target: ".navbar",
                    offset: headerHeight + 2
                });

                var scrollTopPosition = seactionPosition - (headerHeight);

                $('html, body').animate({
                    scrollTop: scrollTopPosition
                }, 800, function () {

                });
            }
        });
        $('body').scrollspy({
            target: ".navbar",
            offset: headerHeight + 2
        });
    }

    /* Header Height ============ */
    var handleResizeElement = function () {
        var headerTop = 0;
        var headerNav = 0;

        $('.header .sticky-header').removeClass('is-fixed');
        $('.header').removeAttr('style');

        if (jQuery('.header .top-bar').length > 0 && screenWidth > 991) {
            //headerTop = parseInt($('.header .top-bar').outerHeight());
        }

        if (jQuery('.header').length > 0) {
            headerNav = parseInt($('.header').height());
            headerNav = (headerNav == 0) ? parseInt($('.header .main-bar').outerHeight()) : headerNav;
        }

        var headerHeight = headerNav + headerTop;

        jQuery('.header').css('height', headerHeight);
    }

    /* Load File ============ */
    var dzTheme = function () {
        'use strict';
        var loadingImage = '<img src="images/loading.gif">';
        jQuery('.dzload').each(function () {
            var dzsrc = siteUrl + $(this).attr('dzsrc');
            //jQuery(this).html(loadingImage);
            jQuery(this).hide(function () {
                jQuery(this).load(dzsrc, function () {
                    jQuery(this).fadeIn('slow');
                });
            })

        });
        //alert(screenWidth);
        /* if (screenWidth < 991) {
            var logoData = jQuery('<div>').append($('.mo-left .logo-header').clone()).html();
            jQuery('.mo-left .header-nav').prepend(logoData);
            jQuery('.mo-left .header-nav .logo-header > a > img').attr('src', 'images/logo.png');
            jQuery('.mo-left.lw .header-nav .logo-header > a > img').attr('src', 'images/logo-white.png');
        } */
    }

    /* Magnific Popup ============ */
    var MagnificPopup = function () {
        'use strict';
        /* magnificPopup function */
        jQuery('.mfp-gallery').magnificPopup({
            delegate: '.mfp-link',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title') + '<small></small>';
                }
            }
        });
        /* magnificPopup function end */

        /* magnificPopup for paly video function */
        jQuery('.video').magnificPopup({
            type: 'iframe',
            iframe: {
                markup: '<div class="mfp-iframe-scaler">' +
                    '<div class="mfp-close"></div>' +
                    '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                    '<div class="mfp-title">Some caption</div>' +
                    '</div>'
            },
            callbacks: {
                markupParse: function (template, values, item) {
                    values.title = item.el.attr('title');
                }
            }
        });
        /* magnificPopup for paly video function end*/
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,

            fixedContentPos: false
        });
    }


    /* Scroll To Top ============ */
    var scrollTop = function () {
        'use strict';
        var scrollTop = jQuery("button.scroltop");
        /* page scroll top on click function */
        scrollTop.on('click', function () {
            jQuery("html, body").animate({
                scrollTop: 0
            }, 1000);
            return false;
        })

        jQuery(window).bind("scroll", function () {
            var scroll = jQuery(window).scrollTop();
            if (scroll > 900) {
                jQuery("button.scroltop").fadeIn(1000);
            } else {
                jQuery("button.scroltop").fadeOut(1000);
            }
        });
        /* page scroll top on click function end*/
    }

    /* handle Accordian ============ */
    var handleAccordian = function () {
        /* accodin open close icon change */
        jQuery('#accordion').on('hidden.bs.collapse', function (e) {
            jQuery(e.target)
                .prev('.panel-heading')
                .find("i.indicator")
                .toggleClass('glyphicon-minus glyphicon-plus');
        });
        jQuery('#accordion').on('shown.bs.collapse', function (e) {
            jQuery(e.target)
                .prev('.panel-heading')
                .find("i.indicator")
                .toggleClass('glyphicon-minus glyphicon-plus');
        });
        /* accodin open close icon change end */
    }

    /* handle Placeholder ============ */
    var handlePlaceholder = function () {
        /* input placeholder for ie9 & ie8 & ie7 */
        jQuery.support.placeholder = ('placeholder' in document.createElement('input'));
        /* input placeholder for ie9 & ie8 & ie7 end*/

        /*fix for IE7 and IE8  */
        if (!jQuery.support.placeholder) {
            jQuery("[placeholder]").focus(function () {
                if (jQuery(this).val() == jQuery(this).attr("placeholder")) jQuery(this).val("");
            }).blur(function () {
                if (jQuery(this).val() == "") jQuery(this).val(jQuery(this).attr("placeholder"));
            }).blur();

            jQuery("[placeholder]").parents("form").submit(function () {
                jQuery(this).find('[placeholder]').each(function () {
                    if (jQuery(this).val() == jQuery(this).attr("placeholder")) {
                        jQuery(this).val("");
                    }
                });
            });
        }
        /*fix for IE7 and IE8 end */
    }

    /* Equal Height ============ */
    var equalHeight = function (container) {

        if (jQuery(container).length == 0) {
            return false
        }

        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = new Array(),
            $el, topPosition = 0;

        $(container).each(function () {
            $el = $(this);
            $($el).height('auto')
            topPostion = $el.position().top;

            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = $el.height();
                rowDivs.push($el);
            } else {
                rowDivs.push($el);
                currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
        });


        /*

        var currentTallest = 0,
        	currentRowStart = 0,
        	rowDivs = new Array(),
        	$el, topPosition = 0;

        $(container).each(function() {
        	$el = $(this);
        	$($el).height('auto')
        	topPostion = $el.position().top;

        	if (currentRowStart != topPostion) {
        		for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        			rowDivs[currentDiv].height(currentTallest);
        		}
        		rowDivs.length = 0; // empty the array
        		currentRowStart = topPostion;
        		currentTallest = $el.height();
        		rowDivs.push($el);
        	} else {
        		rowDivs.push($el);
        		currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        	}
        	for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        		rowDivs[currentDiv].height(currentTallest);
        	}
        });

        */
    }

    /* Footer Align ============ */
    var footerAlign = function () {
        'use strict';
        jQuery('.site-footer').css('display', 'block');
        jQuery('.site-footer').css('height', 'auto');
        var footerHeight = jQuery('.site-footer').outerHeight();
        jQuery('.footer-fixed > .page-wraper').css('padding-bottom', footerHeight);
        jQuery('.site-footer').css('height', footerHeight);
    }

    /* File Input ============ */
    var fileInput = function () {
        'use strict';
        /* Input type file jQuery */
        jQuery(document).on('change', '.btn-file :file', function () {
            var input = jQuery(this);
            var numFiles = input.get(0).files ? input.get(0).files.length : 1;
            var label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
        });

        jQuery('.btn-file :file').on('fileselect', function (event, numFiles, label) {
            input = jQuery(this).parents('.input-group').find(':text');
            var log = numFiles > 10 ? numFiles + ' files selected' : label;

            if (input.length) {
                input.val(log);
            } else {
                if (log) alert(log);
            }
        });
        /* Input type file jQuery end*/
    }

    /* Header Fixed ============ */
    var headerFix = function () {
        'use strict';
        /* Main navigation fixed on top  when scroll down function custom */
        jQuery(window).bind('scroll', function () {
            if (jQuery('.sticky-header').length) {
                var menu = jQuery('.sticky-header');
                if ($(window).scrollTop() > menu.offset().top) {
                    menu.addClass('is-fixed');
                } else {
                    menu.removeClass('is-fixed');
                }
            }
        });
        /* Main navigation fixed on top  when scroll down function custom end*/
    }

    /* Masonry Box ============ */
    var masonryBox = function () {
        'use strict';
        /* masonry by  = bootstrap-select.min.js */
        if (jQuery('#masonry, .masonry').length) {
            var self = $("#masonry, .masonry");
            if (jQuery('.card-container').length) {
                self.imagesLoaded(function () {
                    self.masonry({
                        gutterWidth: 15,
                        isAnimated: true,
                        itemSelector: ".card-container"
                    });
                });
            }
        }
        if (jQuery('.filters').length) {
            jQuery(".filters").on('click', 'li', function (e) {

                jQuery('.filters li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                self.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        //return $(this).attr("data-filter") == filter;
                        return $(this).hasClass(filter);
                    }
                });
            });
        }
        /* masonry by  = bootstrap-select.min.js end */
    }

    /* Use on Shortcode Filter Page ============ */
    var handleMasonryFilter = function () {
        'use strict';

        if (jQuery('#masonry1').length) {
            var masonry1 = $("#masonry1");

            masonry1.imagesLoaded(function () {
                masonry1.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters1").on('click', 'li', function (e) {
                jQuery('.filters1 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry1.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }

        if (jQuery('#masonry2').length) {
            var masonry2 = $("#masonry2");

            masonry2.imagesLoaded(function () {
                masonry2.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters2").on('click', 'li', function (e) {
                jQuery('.filters2 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry2.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }

        if (jQuery('#masonry3').length) {
            var masonry3 = $("#masonry3");

            masonry3.imagesLoaded(function () {
                masonry3.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters3").on('click', 'li', function (e) {
                jQuery('.filters3 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry3.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }

        if (jQuery('#masonry4').length) {
            var masonry4 = $("#masonry4");

            masonry4.imagesLoaded(function () {
                masonry4.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters4").on('click', 'li', function (e) {
                jQuery('.filters4 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry4.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry5').length) {
            var masonry5 = $("#masonry5");

            masonry5.imagesLoaded(function () {
                masonry5.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters5").on('click', 'li', function (e) {
                jQuery('.filters5 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry5.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry6').length) {
            var masonry6 = $("#masonry6");

            masonry6.imagesLoaded(function () {
                masonry6.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters6").on('click', 'li', function (e) {
                jQuery('.filters6 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry6.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry7').length) {
            var masonry7 = $("#masonry7");

            masonry7.imagesLoaded(function () {
                masonry7.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters7").on('click', 'li', function (e) {
                jQuery('.filters7 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry7.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry8').length) {
            var masonry8 = $("#masonry8");

            masonry8.imagesLoaded(function () {
                masonry8.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters8").on('click', 'li', function (e) {

                jQuery('.filters8 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry8.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry9').length) {
            var masonry9 = $("#masonry9");

            masonry9.imagesLoaded(function () {
                masonry9.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters9").on('click', 'li', function (e) {
                jQuery('.filters9 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry9.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry10').length) {
            var masonry10 = $("#masonry10");

            masonry10.imagesLoaded(function () {
                masonry10.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters10").on('click', 'li', function (e) {
                jQuery('.filters10 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry10.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry11').length) {
            var masonry11 = $("#masonry11");

            masonry11.imagesLoaded(function () {
                masonry11.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters11").on('click', 'li', function (e) {
                jQuery('.filters11 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry11.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry12').length) {
            var masonry12 = $("#masonry12");

            masonry12.imagesLoaded(function () {
                masonry12.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters12").on('click', 'li', function (e) {
                jQuery('.filters12 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry12.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry13').length) {
            var masonry13 = $("#masonry13");

            masonry13.imagesLoaded(function () {
                masonry13.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters13").on('click', 'li', function (e) {
                jQuery('.filters13 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry13.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry14').length) {
            var masonry14 = $("#masonry14");

            masonry14.imagesLoaded(function () {
                masonry14.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters14").on('click', 'li', function (e) {
                jQuery('.filters14 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry14.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry15').length) {
            var masonry15 = $("#masonry15");

            masonry15.imagesLoaded(function () {
                masonry15.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters15").on('click', 'li', function (e) {
                jQuery('.filters15 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry15.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry16').length) {
            var masonry16 = $("#masonry16");

            masonry16.imagesLoaded(function () {
                masonry16.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters16").on('click', 'li', function (e) {
                jQuery('.filters16 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry16.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry17').length) {
            var masonry17 = $("#masonry17");

            masonry17.imagesLoaded(function () {
                masonry17.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters17").on('click', 'li', function (e) {
                jQuery('.filters17 li').removeClass('active');
                jQuery(this).addClass('active');

                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry17.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }


        if (jQuery('#masonry18').length) {
            var masonry18 = $("#masonry18");

            masonry18.imagesLoaded(function () {
                masonry18.masonry({
                    gutterWidth: 15,
                    isAnimated: true,
                    itemSelector: ".card-container"
                });
            });



            jQuery(".filters18").on('click', 'li', function (e) {
                jQuery('.filters18 li').removeClass('active');
                jQuery(this).addClass('active');
                e.preventDefault();
                var filter = $(this).attr("data-filter");
                masonry18.masonryFilter({
                    filter: function () {
                        if (!filter) return true;
                        return $(this).hasClass(filter);
                    }
                });
            });

        }

    }


    /* Set Div Height ============ */
    var setDivHeight = function () {
        'use strict';
        var allHeights = [];
        jQuery('.dzseth > div, .dzseth .img-cover, .dzseth .seth').each(function (e) {
            allHeights.push(jQuery(this).height());
        })

        jQuery('.dzseth > div, .dzseth .img-cover, .dzseth .seth').each(function (e) {
            var maxHeight = Math.max.apply(Math, allHeights);
            jQuery(this).css('height', maxHeight);
        })

        allHeights = [];
        /* Removice */
        //var screenWidth = $( window ).width();
        if (screenWidth < 991) {
            jQuery('.dzseth > div, .dzseth .img-cover, .dzseth .seth').each(function (e) {
                jQuery(this).css('height', '');
            })
        }
    }

    /* Counter Number ============ */
    var counter = function () {
        if (jQuery('.counter').length) {
            jQuery('.counter').counterUp({
                delay: 10,
                time: 3000
            });
        }
    }

    /* Video Popup ============ */
    var handleVideo = function () {
        /* Video responsive function */
        jQuery('iframe[src*="youtube.com"]').wrap('<div class="embed-responsive embed-responsive-16by9"></div>');
        jQuery('iframe[src*="vimeo.com"]').wrap('<div class="embed-responsive embed-responsive-16by9"></div>');
        /* Video responsive function end */
    }

    /* Gallery Filter ============ */
    var handleFilterMasonary = function () {
        /* gallery filter activation = jquery.mixitup.min.js */
        if (jQuery('#image-gallery-mix').length) {
            jQuery('.gallery-filter').find('li').each(function () {
                $(this).addClass('filter');
            });
            jQuery('#image-gallery-mix').mixItUp();
        };
        if (jQuery('.gallery-filter.masonary').length) {
            jQuery('.gallery-filter.masonary').on('click', 'span', function () {
                var selector = $(this).parent().attr('data-filter');
                jQuery('.gallery-filter.masonary span').parent().removeClass('active');
                jQuery(this).parent().addClass('active');
                jQuery('#image-gallery-isotope').isotope({
                    filter: selector
                });
                return false;
            });
        }
        /* gallery filter activation = jquery.mixitup.min.js */
    }

    /* handle Bootstrap Select ============ */
    var handleBootstrapSelect = function () {
        /* Bootstrap Select box function by  = bootstrap-select.min.js */
        if (jQuery('select').length) {
            jQuery('select').selectpicker();
        }
        /* Bootstrap Select box function by  = bootstrap-select.min.js end*/
    }

    /* handle Bootstrap Touch Spin ============ */
    var handleBootstrapTouchSpin = function () {
        jQuery("input[name='demo_vertical2']").TouchSpin({
            verticalbuttons: true,
            verticalupclass: 'ti-plus',
            verticaldownclass: 'ti-minus'
        });

    }
    /* Resizebanner ============ */
    var handleBannerResize = function () {
        $(".full-height").css("height", $(window).height());
    }

    /* Countdown ============ */
    var handleCountDown = function (WebsiteLaunchDate) {
        /* Time Countr Down Js */
        if ($(".countdown").length) {
            $('.countdown').countdown({
                date: WebsiteLaunchDate + ' 23:5'
            }, function () {
                $('.countdown').text('we are live');
            });
        }
        /* Time Countr Down Js End */
    }

    /* Content Scroll ============ */
    var handleCustomScroll = function () {
        /* all available option parameters with their default values */
        if ($(".content-scroll").length) {
            $(".content-scroll").mCustomScrollbar({
                setWidth: false,
                setHeight: false,
                axis: "y"
            });
        }
    }

    /* WOW ANIMATION ============ */
    var wow_animation = function () {
        if ($('.wow').length > 0) {
            var wow = new WOW({
                boxClass: 'wow', // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset: 50, // distance to the element when triggering the animation (default is 0)
                mobile: false // trigger animations on mobile devices (true is default)
            });
            wow.init();
        }
    }

    /* Left Menu ============ */
    var handleSideBarMenu = function () {
        $('.openbtn').on('click', function (e) {
            e.preventDefault();
            if ($('#mySidenav').length > 0) {
                document.getElementById("mySidenav").style.left = "0";
            }

            if ($('#mySidenav1').length > 0) {
                document.getElementById("mySidenav1").style.right = "0";
            }

        })

        $('.closebtn').on('click', function (e) {
            e.preventDefault();
            if ($('#mySidenav').length > 0) {
                document.getElementById("mySidenav").style.left = "-300px";
            }

            if ($('#mySidenav1').length > 0) {
                document.getElementById("mySidenav1").style.right = "-820px";
            }
        })
    }

    /* Range ============ */
    var priceslider = function () {

        if ($(".price-slide, .price-slide-2").length > 0) {
            $("#slider-range,#slider-range-2").slider({
                range: true,
                min: 300,
                max: 4000,
                values: [0, 5000],
                slide: function (event, ui) {
                    var min = ui.values[0],
                        max = ui.values[1];
                    $('#' + this.id).prev().val("$" + min + " - $" + max);
                }
            });
        }
    }

    /* BGEFFECT ============ */
    var handleBGElements = function () {

        if (screenWidth > 1023) {
            if (jQuery('.bgeffect').length) {
                var s = skrollr.init({
                    edgeStrategy: 'set',
                    easing: {
                        WTF: Math.random,
                        inverted: function (p) {
                            return 1 - p;
                        }
                    }
                });
            }
        }
    }

    var boxHover = function () {

        jQuery('.box-hover').on('mouseenter', function () {
            jQuery('.box-hover').removeClass('active');
            jQuery(this).addClass('active');

        })
    }

    /* Light Gallery ============ */
    var lightGallery = function () {
        if (($('#lightgallery, .lightgallery').length > 0)) {
            $('#lightgallery, .lightgallery').lightGallery({
                selector: '.check-km',
                loop: true,
                thumbnail: true,
                exThumbImage: 'data-exthumbimage'
            });
        }
    }

    var reposition = function () {
        'use strict';
        var modal = jQuery(this),
            dialog = modal.find('.modal-dialog');
        modal.css('display', 'block');

        /* Dividing by two centers the modal exactly, but dividing by three
         or four works better for larger screens.  */
        dialog.css("margin-top", Math.max(0, (jQuery(window).height() - dialog.height()) / 2));
    }

    var handelResize = function () {

        /* Reposition when the window is resized */
        jQuery(window).on('resize', function () {
            jQuery('.modal:visible').each(reposition);

            equalHeight('.equal-wraper .equal-col');
            footerAlign();
        });
    }

    /* Website Launch Date */
    var WebsiteLaunchDate = new Date();
    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    WebsiteLaunchDate.setMonth(WebsiteLaunchDate.getMonth() + 1);
    WebsiteLaunchDate = WebsiteLaunchDate.getDate() + " " + monthNames[WebsiteLaunchDate.getMonth()] + " " + WebsiteLaunchDate.getFullYear();
    /* Website Launch Date END */


    /* Function ============ */
    return {
        init: function () {
            boxHover();
            wow_animation();
            priceslider();
            onePageLayout();
            dzTheme();
            handleResizeElement();
            homeSearch();
            MagnificPopup();
            handleAccordian();
            scrollTop();
            handlePlaceholder();
            footerAlign();
            fileInput();
            headerFix();
            setDivHeight();
            handleVideo();
            // handleFilterMasonary();
            handleCountDown(WebsiteLaunchDate);
            handleCustomScroll();
            handleSideBarMenu();
            cartButton();
            handleBannerResize();
            handelResize();
            // handleSupport();
            jQuery('.modal').on('show.bs.modal', reposition);
            lightGallery();
        },

        /* handleMasonryFilter: function () {
            handleMasonryFilter();
        }, */

        load: function () {
            handleBGElements();
            handleBootstrapSelect();
            handleBootstrapTouchSpin();
            equalHeight('.equal-wraper .equal-col');
            equalHeight('.equal-wraper2 .equal-col');
            equalHeight('.equal-wraper3 .equal-col');
            equalHeight('.equal-wraper4 .equal-col');
            counter();
            // masonryBox();

        },
        resize: function () {
            screenWidth = $(window).width();
            setTimeout(function () {
                handleResizeElement();
            }, 200);


        }
    }

}();

/* Document.ready Start */
document.addEventListener('DOMContentLoaded', function () {

    YogaZone.init();

    var screenWidth = jQuery(window).width();
    if (screenWidth <= 991) {
        jQuery('.navbar-nav > li > a, .sub-menu > li > a').on('click', function (e) {
            //e.preventDefault();
            if (jQuery(this).parent().hasClass('open')) {
                jQuery(this).parent().removeClass('open');
            } else {
                jQuery(this).parent().parent().find('li').removeClass('open');
                jQuery(this).parent().addClass('open');
            }
        });

    }

    jQuery('.navicon').on('click', function () {
        $(this).toggleClass('open');
    });

    $('a[data-bs-toggle="tab"]').click(function () {
        // todo remove snippet on bootstrap v4
        $('a[data-bs-toggle="tab"]').click(function () {
            $($(this).attr('href')).show().addClass('show active').siblings().hide();
        })
    });

    /* Window Load START */
    $(window).on("load", function (e) {
        YogaZone.load();
        setTimeout(function () {
            jQuery('#loading-area').remove();
        }, 0);
    });

    /* Window Resize START */
    jQuery(window).on('resize', function () {
        'use strict';
        YogaZone.resize();
    });
    /*  Window Resize END */
}, false);
/* Document.ready END */
