/* JavaScript Document */
document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    /* image-carousel function by = owl.carousel.js */
    jQuery('.img-carousel').owlCarousel({
        loop: true,
        margin: 30,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            767: {
                items: 3
            },
            1000: {
                items: 4
            }
        }
    })

    /* image-carousel no margin function by = owl.carousel.js */
    jQuery('.img-carousel-content').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 30,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            767: {
                items: 3
            },
            1000: {
                items: 4
            }
        }
    })

    /*  Portfolio Carousel function by = owl.carousel.js */
    jQuery('.portfolio-carousel').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 30,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            767: {
                items: 3
            },
            1000: {
                items: 4
            }
        }
    })

    /*  Portfolio Carousel no margin function by = owl.carousel.js */
    jQuery('.portfolio-carousel-nogap').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 0,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },

            480: {
                items: 2
            },

            767: {
                items: 3
            },
            1000: {
                items: 4
            }
        }
    })

    /*  Blog post Carousel function by = owl.carousel.js */
    jQuery('.blog-carousel').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 30,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            767: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    })

    /*  Blog post Carousel function by = owl.carousel.js */
    jQuery('.event-carousel').owlCarousel({
        loop: true,
        margin: 30,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },

            480: {
                items: 2
            },

            767: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    })

    /*  Blog post Carousel function by = owl.carousel.js */
    jQuery('.client-logo-carousel').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 30,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },

            480: {
                items: 2
            },

            767: {
                items: 3
            },
            1000: {
                items: 5
            }
        }
    })

    /* Fade slider for home function by = owl.carousel.js */
    jQuery('.owl-fade-one').owlCarousel({
        loop: true,
        autoplay: true,
        autoplayTimeout: 2000,
        margin: 30,
        nav: true,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        items: 1,
        dots: false,
        animateOut: 'fadeOut',
    })

    /*  testimonial one function by = owl.carousel.js */
    jQuery('.testimonial-one').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 30,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },

            480: {
                items: 1
            },

            767: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    })

    /* testimonial two function by = owl.carousel.js */
    jQuery('.testimonial-two').owlCarousel({
        loop: true,
        margin: 30,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },

            480: {
                items: 2
            },

            767: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    })

    /*  testimonial three function by = owl.carousel.js */
    jQuery('.testimonial-three').owlCarousel({
        loop: true,
        margin: 30,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },

            480: {
                items: 2
            },

            767: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    })

    /*  testimonial four function by = owl.carousel.js */
    jQuery('.testimonial-four').owlCarousel({
        loop: true,
        margin: 80,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            991: {
                items: 2
            }
        }
    })

    /*  testimonial four function by = owl.carousel.js */
    jQuery('.testimonial-five').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 30,
        nav: false,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            991: {
                items: 2
            }
        }
    })

    jQuery('.testimonial-six').owlCarousel({
        loop: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 15000,
        margin: 30,
        nav: true,
        dots: false,
        navText: ['<i class="flaticon-left-arrow"></i>', '<i class="flaticon-right-arrow"></i>'],
        responsive: {
            0: {
                items: 1,
                nav: false
            },

            480: {
                items: 1,
                nav: false
            },

            767: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

    /*  Our Classes function by = owl.carousel.js */
    jQuery('.our-classes').owlCarousel({
        loop: true,
        margin: 30,
        nav: false,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },

            480: {
                items: 2
            },

            767: {
                items: 3
            },
            1000: {
                items: 4
            },
            1200: {
                items: 5
            }
        }
    })

    /* blog curser */
    jQuery('.carousel-gallery').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: false,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 4
            },
            480: {
                items: 5
            },
            1024: {
                items: 7
            },
            1200: {
                items: 10
            }
        }
    })

}, false);
/* Document .ready END */
