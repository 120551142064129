require('./bootstrap');
require('./general');
require('./jquery.bootstrap-touchspin');
require('./magnific-popup');
require('./waypoints');
require('./counterup');
require('./imagesloaded');
// require('./masonry');
// require('./masonry-filter');
require('./owl.carousel');
require('./custom');
require('./dz.carousel');
require('./revolution');
require('./revolution.tools');
require('./revolution.extension.actions');
require('./revolution.extension.kenburn');
require('./revolution.extension.layeranimation');
require('./revolution.extension.parallax');
require('./revolution.extension.slideanims');
require('./rev.slider');
