import Swiper from 'swiper/bundle';

window.alertHtml = (text, type) => {
    if (typeof type == "undefined") {
        type = "danger";
    }

    return `
        <div class="alert alert-${type} my-3">
            <h4 class="mb-0 text-center">
                ${text}
            </h4>
        </div>
    `;
};

window._ajax = (endpoint, data, type, isFormData) => {
    if (typeof type == "undefined") {
        type = "POST";
    }

    if (typeof isFormData == "undefined") {
        isFormData = false;
    }

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    if (isFormData) {
        return $.ajax({
            url: '/api/' + endpoint,
            data: data,
            type: type,
            processData: false,
            contentType: false,
            cache: false,
            dataType: 'json'
        });
    } else {
        return $.ajax({
            url: '/api/' + endpoint,
            data: data,
            type: type,
            cache: false,
            dataType: 'json'
        });
    }
};

window.parseLaravelError = xhr => {
    let message = '';

    if (typeof xhr.responseText != "undefined" && xhr.responseText != '') {
        try {
            let response = JSON.parse(xhr.responseText);
            console.log(response);

            message = response.message;
            if (typeof response.errors != "undefined") {
                for (const [key, value] of Object.entries(response.errors)) {
                    if (value.isArray) {
                        for (let index = 0; index < value.length; index++) {
                            const line = value[index];
                            message += '<br>' + line;
                        }
                    } else {
                        message += '<br>' + value;
                    }
                }
            }

        } catch (error) {
            message = 'An error has occurred while trying to process your request.';
        }
    } else {
        message = 'An error has occurred while trying to process your request.';
    }

    return message;
}

window.floatingMessage = function (text, type) {
    if (typeof text == "undefined") {
        text = "";
    }

    if (typeof type == "undefined") {
        type = "success";
    }

    $('body').append(`
        <div class="alert alert-custom alert-${type}" id="floatMessage">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
            <p>
                <i class="fas ${type == "success" ? 'fa-check-circle' : 'fa-exclamation-triangle'}" aria-hidden="true"></i>
                ${text}
            </p>
        </div>
    `);

    let message = $('#floatMessage');
    setTimeout(function () {
        message.remove();
    }, 7000);
}

window.__ = function(key, replace = {}) {
    let translation = key.split('.').reduce((t, i) => t[i] || null, window._TRANSLATIONS);

    for (var placeholder in replace) {
        translation = translation.replace(`:${placeholder}`, replace[placeholder]);
    }

    return translation;
}

document.addEventListener("DOMContentLoaded", function () {

});
